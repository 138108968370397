import { createElement as e, createContext } from 'react'

import renderSchema from './index'

export const Context = createContext({
  renderSchema,
})

export const Provider = ({ createElement, library, children }) => {
  return e(
    Context.Provider,
    {
      value: {
        renderSchema: s =>
          renderSchema({
            schema: s.schema || s,
            createElement,
            library,
          }),
      },
    },
    children,
  )
}

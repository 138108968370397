import {
  fluidLocks as fluidL,
  fluidColumn as fluidCol,
  // u,
  // col,
  // excessVHeight,
  // headings as heads,
  ratioFromDif,
  modularScale,
  createPalettes,
  pickAndCustomizePalette,
  chooseColor,
  // HELPERS
  // setAlign,
  // setMargins,
  // setPaddings,
  // setFlex,
  // setPosition,
} from '.'

export const initialize = ({
  theme: {
    colors: unsafeColors = { black: '#000', white: `#fff`, grey: `#888` },
    palettes: userPalettes = [
      {
        name: 'defaultBN',
        neutral: 'white',
        primary: 'black',
        secondary: 'grey',
      },
    ],

    fontFamily = 'Roboto,Source Sans Pro,Helvetica,sans-serif',
    baseFontSize = 1,
    h1FontSize = 2.25,
    baseLineHeight = 1.4,
    // fontSizeScale = 1.225,
    fullWidth = 60, // this is in em
    // fullScaleWidth = 0.8,
  },
  defaults: createDefaults,
}) => {
  const colors = Array.isArray(unsafeColors)
    ? unsafeColors.reduce((accu, color) => {
        return {
          ...accu,
          [color.name]: color.value,
        }
      }, {})
    : unsafeColors

  // TODO: complement colors
  // const colors = complementColors(colors)
  const color = colorString => chooseColor(colors, colorString)
  const palettes = createPalettes({ colors, palettes: userPalettes })
  const palette = (customPalette, variation, cb) =>
    pickAndCustomizePalette({
      colors,
      palettes,
      palette: customPalette,
      variation,
      cb,
    })

  const u = (num, unit = 'em') => `${(num * fullWidth) / 120}${unit}` // 0.5em canonical
  const tem = (num, unit = 'em') => `${(num * fullWidth) / 60}${unit}` // 1em canonical
  const cell = (num, unit = 'em') => `${(num * fullWidth) / 12}${unit}` // 5em canonical
  const col = (num, unit = 'em') => `${(num * fullWidth) / 4}${unit}` // 15em canonical
  const prct = (num, unit = 'em') => `${(num * fullWidth) / 100}${unit}` // 0.6em canonical

  const units = { u, tem, cell, col, prct }

  const fontSizeScale = ratioFromDif({
    min: baseFontSize,
    max: h1FontSize,
    steps: 5,
  })
  const typography = {
    fontFamily,
    baseFontSize,
    h1FontSize,
    baseLineHeight,
    fontSizeScale,
    // useful for a boxed design so we comute on 80vw instead of 100 for example.
    // always 1 at < 15em canonical. fullWidth is 60em canonical
    // fullScaleWidth,
  }

  const scale = i =>
    modularScale(i, { base: baseFontSize, ratio: fontSizeScale })

  const h = i => scale(6 - i)

  // TODO: should it be computed with lh as base or set at the end ???
  const lhScale = i => scale(i) * baseLineHeight
  // const lhScale = i =>
  //   modularScale(i, { base: baseLineHeight, ratio: fontSizeScale })
  const reducedScale = i => scale(i) / baseLineHeight

  const scales = {
    scale,
    h,
    lh: lhScale,
    reducedScale,
  }

  const fluidLocks = locks =>
    Array.isArray(locks)
      ? fluidL(
          locks.map(lock => ({
            ...lock,
            maxW: lock.maxW || [fullWidth],
          })),
        )
      : fluidL({
          ...locks,
          maxW: locks.maxW || [fullWidth],
        })
  const fluidColumn = ({
    properties,
    maxCols,
    fullWidth: fullW = fullWidth,
    takesCols,
  }) =>
    fluidCol({
      properties,
      maxCols,
      fullWidth: fullW,
      takesCols,
    })

  const fluid = {
    locks: fluidLocks,
    column: fluidColumn,
    // column: fluidCol,
  }

  const theme = {
    colors,
    color,
    palettes,
    palette,

    units,
    scales,
    fluid,
    typography,
  }
  const defaults = createDefaults && createDefaults(theme)

  return {
    ...theme,
    defaults,
  }
}

// * Define ':root' font size in % to compensate differences between fonts
// * Set line height (numerical except if we can not use cssFluidLock with simple numbers)
// * decide min and max font size in rem unit
// * Molten Leading: triadic relationship in typesetting — that of a text’s font size, line height, and line length
// * Breakpoints could be set like:
//    - max: 150 rem (bc 150 * 16px = 2400px)             | 120rem = 1920px
//    - large-monitor: > 75 rem (bc 75 * 16px = 1200px)   | 100rem = 1600px
//    - monitor: > 60 rem (bc 60 * 16px = 960px)          | 80rem = 1280px
//    - tablet: > 45 rem (bc 45 * 16px = 720px)           | 60rem = 960px
//    - phablet: > 30 rem (bc 30 * 16px = 480px)          | 40rem = 640px
//    - phone: > 15 rem (bc 15 * 16px = 240px)            | 20rem = 320px
//    BUT maybe we don't need that much breakpoints if the layout is fluid
//    let's make a base column unit of 15 rem -> ok for short text. between 30 and 45 is nice for longer texts
//    with fontSize scaling of 1.25, it takes us from 60 to 75 rem so we can reason about the grid on a basis of 60rem
//      with that logic, a column will be between 240px and 300px
//      simple grids by setting widths (and heights) to a multiple of 15em
//      I could even compute complex fluid grids with the formula: from 15em to 30em between 0 > 30 then the same between 30 > 45, etc
// * With these breakpoints, 75em / 120 units of space = 0.625em = 1 unit
//    Fixed sized blocks could use this unit system
//    => large-m is 120 units wide, monitor is 96, tablet is 72, phablet is 48, phone is 24
//    better fix it on 60em and benefit from the scalling
//    => 1 column = 15em = 3 units. 60em at full scale takes us at 1200px and we have 12 units of 5em or 120 units of .5em
//    /!\ Don't forget that this is only valid for horizontal spaces ! For ySpacing vertical rhythm is moreimportant
// * We can define a few contexts. (like 'important', 'normal', 'small')
// * Then we cross contexts with usage
//    texts and headings: based on min and max base font size and modular scale
//    spaces (margins and paddings): depending on wether it is a row or column element we may want to only set vertical or h. spaces (could also be defined by the chosen modular scale)

// We can have contexts for palettes, frames & spaces & font sizes,  (rows & columns & behavior when it wraps), caligraphy (?), ...
// Then we have (visual) artifacts for things like a leaned section separator, etc

// COLORS can be applied on ... see https://developer.mozilla.org/en-US/docs/Web/HTML/Applying_color
// For breakpoints, use a technique like mappy-breakpoint https://github.com/zellwk/mappy-breakpoints

// Values should be compued at the top then we should go into more precise control
// 1. computation of base values to use throughout the Project
// 2. Defining contexts
// 3. Defining which component use which context so we have all the styles in one file

//
// ----------------------------------------------------------------------------
//

// export const fontSizes = {
//   h1: tem(h(1)),
//   h2: tem(h(3)),
//   h3: tem(h(5)),
//   h4: tem(scale(0)),
//   subTitle: tem(h(5)),
//   p: tem(scale(0)),
// }
// export const paddings = {
//   column: tem(scale(0)),
//   section: tem(h(1)),
//   innerSection: tem(h(-1), 'rem'),
//   sectionTitleBottom: tem(h(3), 'rem'),
//   sectionDescriptionBottom: tem(scale(0), 'rem'),
// }
//
// export const styles = {
//   afterSectionTitle: {
//     display: 'block',
//     content: "''",
//     // width: '3.25em',
//     width: cell(1, 'rem'),
//     height: '2px',
//     margin: `${tem(scale(0), 'rem')} 0 ${tem(h(2), 'rem')}`,
//     borderRadius: '2px',
//     backgroundColor: '#ddd',
//     backgroundImage: 'linear-gradient(90deg,#2bcaaf,#125449)',
//   },
// }
//
// // Widths are containers widths while ...
// export const widths = {
//   unit: u(1), // 0.5em canonical
//   cell: col(1 / 3), // === u(10)
//   column: col(1), // 15em canonical = 240px
//   paragraph: col(2),
// }
// // Spaces are paddings
// const spaces = {}

// export default {
//   misc: {
//     zIndexBase: 10000,
//   },
//   duration: {
//     menu: '0.35s',
//     transition: '0.2s',
//     banner: '2.5s',
//   },
//   size: {
//     borderRadius: '4px',
//     elementHeight: '2.75em',
//     elementMargin: '2em',
//     inner: '65em',
//   },
//   font: {
//     family: "'Source Sans Pro', Helvetica, sans-serif",
//     familyFixed: "'Courier New', monospace",
//     weight: 300,
//     weightBold: 600,
//     letterSpacing: '0.025em',
//     letterSpacingAlt: '0.25em',
//   },
//   breakpoints: {
//     large: '@media screen and (max-width: 1280px)',
//     medium: '@media screen and (max-width: 980px)',
//     small: '@media screen and (max-width: 736px)',
//   },
// }

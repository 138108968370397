import React from 'react'
import { renderToString } from 'react-dom/server'
import Obfuscate from 'react-obfuscate'

import Link from '../core/components/Link'

const protectAndReplaceEmails = str => {
  if (typeof str !== 'string') return str
  // const emailRe = /\S+[a-z0-9]@[a-z0-9\.]+/gim
  // const emailRe = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
  // Source: http://emailregex.com/
  const emailRe = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi
  // From W3C
  // const emailRe = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g
  // const s =
  //   typeof window === 'undefined'
  //     ? str.replace(emailRe, new Array(10).join('&thinsp; '))
  //     : str.replace(emailRe, `<a href="mailto:$&">$&</a>`)
  // const s = str.replace(emailRe, match =>
  //   typeof window === 'undefined'
  //     ? // ? `<code>${new Array(match.length).join('&thinsp;')}<code>`
  //       new Array(match.length).join('&thinsp; ')
  //     : `<a href="mailto:${match}">${match}</a>`,
  // )

  // const s = str.replace(emailRe, match => {
  //   const [name, end] = match.split('@')
  //   const [domain, tld] = end.split('.')
  //
  //   return `<a href="#" class="cryptedmail" data-name="${name}" data-domain="${domain}" data-tld="${tld}"
  //   onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"
  // ></a>`
  // })

  const s = str.replace(emailRe, match => {
    const obfuscated = renderToString(<Obfuscate email={match} />)
    return obfuscated
  })

  // const s = str.replace(emailRe, match => {
  //   const obfuscated = renderToString(
  //     <Link to={`mailto:${match}`}>{match}</Link>,
  //   )
  //   return obfuscated
  // })

  // const ssr = str.replace(emailRe, new Array(10).join('&thinsp; '))
  // const browser = str.replace(emailRe, `<a href="mailto:$&">$&</a>`)
  // return { ssr, browser }

  return s
}

export default protectAndReplaceEmails

// const protectEmail = htmlString => {
//   const codeRegex = new RegExp('<a href="mailto:.+?</a>', "g");
//   let newHtml = htmlString;
//   // const matches = newHtml.match(codeRegex)
//   newHtml = newHtml.replace(codeRegex, match => {
//     return typeof window !== "undefined" ? match : "";
//   });
//   return newHtml;
// };
// export { protectEmail };

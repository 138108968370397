import { createElement, createContext } from 'react'
import { initialize } from './utils/initialize'
import * as cssHelpers from './utils/cssHelpers'

export const Context = createContext(initialize({ theme: {} }))

export const Provider = ({ theme, defaults, children }) =>
  createElement(
    Context.Provider,
    {
      value: {
        cssHelpers,
        ...initialize({ theme, defaults }),
      },
    },
    children,
  )

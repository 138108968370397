import React from 'react'
import { reduce, values } from 'rambda'
import GImg from 'gatsby-image'
// import IdealImage from 'react-ideal-image'
import { renderSchema } from '../functions'

const hasValues = o =>
  reduce((accu, val) => Boolean(accu || val), false, values(o))

const shapeAspectRatios = {
  none: 1,
  square: 1,
  circle: 1,
  landscape: 9 / 16,
  portrait: 16 / 9,
}

const CoreImage = ({
  children,
  tag: Tag,
  // data = { props: {} },
  data,
  src,
  alt,
  fit,
  shape,
  size,
  width: w,
  height: h,
  border,
  // isFluid,
  padding = 0,
  position,
  ...props
}) => {
  // const { props: dataProps } = data || {}
  // const w =
  //   width ||
  //   (size && `calc(${size} / ${shapeAspectRatios[shape || 'square']})`) ||
  //   `100%`
  // const h = height || size || `100%`
  // const h = height || size * shapeAspectRatios[shape || 'square']
  const width = w || size
  const height = h || size
  // const border = border

  // const positionParams = position ||
  //   (isFluid && {
  //     position: 'absolute',
  //     zIndex: 0,
  //     top: padding || 0,
  //     // right: padding || 0,
  //     // bottom: padding || 0,
  //     left: padding || 0,
  //     width: `calc(100% - (2 * ${padding}))`,
  //     height: `calc(100% - (2 * ${padding}))`,
  //   }) || {
  //     position: 'relative',
  //     width: w,
  //     height: h,
  //   }

  // const imgProps = {
  //   ...dataProps,
  //   ...(src && { src }),
  //   ...(alt && { alt }),
  // }

  // return (
  //   <GImg
  //     {...{
  //       css: {
  //         width: 200,
  //         height: 200,
  //       },
  //       fluid: {
  //         ...data.props,
  //         // aspectRatio,
  //         // base64,
  //         // tracedSVG,
  //         // aspectRatio,
  //         // src,
  //         // srcSet,
  //         // srcWebp,
  //         // srcSetWebp,
  //         // sizes,
  //         // originalImg,
  //       },
  //     }}
  //   />
  // )
  return data
    ? renderSchema({
        schema: {
          component: GImg,
          fluid: {
            ...data?.props,
            aspectRatio:
              (shape && shapeAspectRatios[shape]) ||
              data.props.aspectRatio ||
              1,
          },
          // ...imgProps,
          ...props,
          style: {
            ...(width && { width }),
            ...(height && { height }),
            ...(shape === 'circle' && { borderRadius: '50%' }),
          },
          imgStyle: {
            ...(fit && { objectFit: fit }),
            // ...(padding && {
            //   top: padding,
            //   right: padding,
            //   bottom: padding,
            //   left: padding,
            //   width: `calc(100% - (2 * ${padding}))`,
            //   height: `calc(100% - (2 * ${padding}))`,
            // }),
          },
        },
      })
    : null

  // return hasValues(imgProps)
  //   ? renderSchema({
  //       schema: {
  //         component: Tag || 'div',
  //         className: `image ${props.className}`,
  //         css: [
  //           {
  //             maxWidth: '100%',
  //             position: 'relative',
  //             zIndex: 0,
  //           },
  //         ],
  //         ...props,
  //         children: [
  //           {
  //             component: 'img',
  //             ...imgProps,
  //             css: [
  //               {
  //                 display: 'block',
  //                 // width: w,
  //                 // height: h,
  //                 ...positionParams,
  //                 maxWidth: '100%',
  //                 maxHeight: '100%',
  //                 objectFit: fit || 'contain',
  //                 overflow: 'hidden',
  //                 borderRadius: shape === 'circle' ? '50%' : 0,
  //                 ...bor,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     })
  //   : null
}

export default CoreImage

// export default ({
//   children,
//   tag: Tag,
//   src,
//   fit,
//   css,
//   alt,
//
//   width,
//   height,
//
//   ...props
// }) => {
//   return src
//     ? renderSchema({
//         schema: {
//           component: RIImg,
//           alt, //	string	Passed to the img element
//           // src,
//           height,
//           width,
//           srcSet: [
//             {
//               width, // number - required
//               src, // string
//               // size, // number,
//               // format, // string - one of the 'jpeg' or 'webp'
//             },
//           ],
//
//           placeholder: {
//             color: 'red',
//             lqip: '',
//           },
//
//           css: [
//             {
//               maxWidth: '100%',
//               objectFit: fit || 'contain',
//             },
//             css,
//           ],
//           ...props,
//         },
//       })
//     : null
// }

// export default ({
//   children,
//   tag: Tag,
//   src,
//   fixed,
//   css,
//
//   fadeIn,
//   title,
//   alt,
//   className,
//   style,
//   imgStyle,
//   placeholderStyle,
//   placeholderClassName,
//   backgroundColor,
//   onLoad,
//   onStartLoad,
//   onError,
//   critical,
//   fit,
//   ...props
// }) => {
//   return src
//     ? renderSchema({
//         schema: {
//           component: GImg,
//           fixed: fixed && {
//             src,
//           },
//           fluid: !fixed && {
//             src,
//             srcSet: '',
//             sizes: '',
//             aspectRatio: 1,
//           },
//           fadeIn, // bool	Defaults to fading in the image on load
//           title, // string	Passed to the img element
//           alt, //	string	Passed to the img element
//           className, //	string / object	Passed to the wrapper element. Object is needed to support Glamor’s css prop
//           style, //	object	Spread into the default styles of the wrapper element
//           imgStyle, //	object	Spread into the default styles of the actual img element
//           placeholderStyle, //	object	Spread into the default styles of the placeholder img element
//           placeholderClassName, //	string	A class that is passed to the placeholder img element
//           backgroundColor, //	string / bool	Set a colored background placeholder. If true, uses “lightgray” for the color. You can also pass in any valid color string.
//           onLoad, // func	A callback that is called when the full-size image has loaded.
//           onStartLoad, //	func	A callback that is called when the full-size image starts loading, it gets the parameter { wasCached: boolean } provided.
//           onError, //	func	A callback that is called when the image fails to load.
//           Tag, //	string	Which HTML tag to use for wrapping elements. Defaults to div.
//           critical, // bool	Opt-out of lazy-loading behavior. Defaults to false.
//           css: [
//             {
//               maxWidth: '100%',
//               objectFit: fit || 'contain',
//             },
//             css,
//           ],
//           ...props,
//         },
//       })
//     : null
// }

import { Fragment, useContext } from 'react'
import { Global } from '@emotion/core'

import { Context as RenderSchemaContext } from '../functions/renderSchema/Context'

const GlobalStyles = ({ styles, children }) => {
  const { renderSchema } = useContext(RenderSchemaContext)

  return renderSchema({
    component: Fragment,
    children: [
      {
        component: Global,
        key: 'Global',
        styles,
      },
      ...children,
    ],
  })
}

export default GlobalStyles

// ALIGN
// ----------------------------------------------------------------------------
export const setAlign = (side = 'left') => ({
  textAlign: side,
  ' img, .image': {
    ...(/center|right/.test(side) && { marginLeft: 'auto' }),
    ...(/center|left/.test(side) && { marginRight: 'auto' }),
  },
})
export const center = {
  textAlign: 'center',
  ' img, .image': {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
}
export const left = {
  textAlign: 'left',
  ' img, .image': {
    marginRight: 'auto',
  },
}
export const right = {
  textAlign: 'right',
  ' img, .image': {
    marginLeft: 'auto',
  },
}

// FLEX
// ----------------------------------------------------------------------------
export const setFlex = ({
  flow = 'row wrap',
  justify = 'flex-start',
  align: a,
  alignItems,
  alignContent,
} = {}) => ({
  display: 'flex',
  flexFlow: flow,
  justifyContent: justify,
  alignItems: alignItems || a || 'baseline',
  alignContent: alignContent || justify || 'stretch',
})

// PADDINGS AND MARGINS AND BORDERS
// ----------------------------------------------------------------------------
export const setSpaces = (
  mOrPOrB,
  { p: pad, m, border, all, x, y, t, r, b, l },
  a,
) => {
  const p = pad || m || border || all
  if (!p && !a) {
    // if no argument we set the defaults
    return {
      [`${mOrPOrB}Top`]: t || y || p,
      [`${mOrPOrB}Right`]: r || x || p,
      [`${mOrPOrB}Bottom`]: b || y || p,
      [`${mOrPOrB}Left`]: l || x || p,
    }
  }

  // a value is given for p but we don't know where to put it so we put it everywhere
  if (typeof a === 'undefined') return { [`${mOrPOrB}`]: p }

  if (Array.isArray(a)) {
    if (a.length === 2) {
      return {
        ...(a[0] && {
          [`${mOrPOrB}Top`]:
            typeof a[0] === 'string' ? a[0] : `calc(${t || y || p} * ${a[0]})`,
          [`${mOrPOrB}Bottom`]:
            typeof a[0] === 'string' ? a[0] : `calc(${b || y || p} * ${a[0]})`,
        }),
        ...(a[1] && {
          [`${mOrPOrB}Right`]:
            typeof a[1] === 'string' ? a[1] : `calc(${r || x || p} * ${a[1]})`,
          [`${mOrPOrB}Left`]:
            typeof a[1] === 'string' ? a[1] : `calc(${l || x || p} * ${a[1]})`,
        }),
      }
    }
    if (a.length === 3) {
      return {
        ...(a[0] && {
          [`${mOrPOrB}Top`]:
            typeof a[0] === 'string' ? a[0] : `calc(${t || y || p} * ${a[0]})`,
        }),
        ...(a[1] && {
          [`${mOrPOrB}Right`]:
            typeof a[1] === 'string' ? a[1] : `calc(${r || x || p} * ${a[1]})`,
          [`${mOrPOrB}Left`]:
            typeof a[1] === 'string' ? a[1] : `calc(${l || x || p} * ${a[1]})`,
        }),
        ...(a[2] && {
          [`${mOrPOrB}Bottom`]:
            typeof a[2] === 'string' ? a[2] : `calc(${b || y || p} * ${a[2]})`,
        }),
      }
    }
    if (a.length === 4) {
      return {
        ...(a[0] && {
          [`${mOrPOrB}Top`]:
            typeof a[0] === 'string' ? a[0] : `calc(${t || y || p} * ${a[0]})`,
        }),
        ...(a[1] && {
          [`${mOrPOrB}Right`]:
            typeof a[1] === 'string' ? a[1] : `calc(${r || x || p} * ${a[1]})`,
        }),
        ...(a[2] && {
          [`${mOrPOrB}Bottom`]:
            typeof a[2] === 'string' ? a[2] : `calc(${b || y || p} * ${a[2]})`,
        }),
        ...(a[3] && {
          [`${mOrPOrB}Left`]:
            typeof a[3] === 'string' ? a[3] : `calc(${l || x || p} * ${a[3]})`,
        }),
      }
    }
  }
  return null
}
export const setPaddings = (...args) => setSpaces('padding', ...args)
export const setMargins = (...args) => setSpaces('margin', ...args)
export const setBorders = (...args) => setSpaces('border', ...args)

// POSITION
// ----------------------------------------------------------------------------
export const setPosition = (
  pos = 'relative',
  [t, r, b, l] = [],
  {
    clip, // Clips an absolutely positioned element
    z,
  } = {},
) => ({
  position: pos,
  ...(/number|string/.test(typeof t) && { top: t }),
  ...(/number|string/.test(typeof r) && { right: r }),
  ...(/number|string/.test(typeof b) && { bottom: b }),
  ...(/number|string/.test(typeof l) && { left: l }),
  ...(/number|string/.test(typeof z) && { zIndex: z }),
  ...(/number|string/.test(typeof clip) && { clip }),
})

// BORDER RADII
// ----------------------------------------------------------------------------
// TODO: NOT READY !! calc can not be used like that. I should go back to defining each individual property like for spaces
export const setBorderRadii = (
  params,
  // 'down' means the diagonal down, so tl and br, 'up' ...
  a,
) => {
  const rad = params.radius || params.all
  const { down = rad, up = rad } = params
  const {
    tl = params.down,
    tr = params.up,
    br = params.down,
    bl = params.up,
  } = params
  // const [a0 = a || 1] = a
  // const [x, a1 = a0] = a
  // const [y, z, a2 = a0, a3 = a1] = a

  const setTheOne = (coef = 1) =>
    /string|number/.test(typeof rad) && {
      borderRadius: `calc(${rad} * ${coef})`,
    }
  const setCross = (one = 1, two = 1) =>
    /string|number/.test(typeof up) &&
    /string|number/.test(typeof down) && {
      borderRadius: `calc((${down} * ${one}) (${up} * ${two}))`,
    }
  const setEach = (one = 1, two = 1, three = 1, four = 1) =>
    /string|number/.test(typeof tl) &&
    /string|number/.test(typeof tr) &&
    /string|number/.test(typeof br) &&
    /string|number/.test(typeof bl) && {
      borderRadius: `calc((${tl} * ${one}) (${tr} * ${two}) (${br} * ${three}) (${bl} * ${four}))`,
    }

  if (!a) {
    // if no argument we set the defaults
    return {
      ...setTheOne(),
      ...setCross(),
      ...setEach(),
    }
  }

  if (Array.isArray(a)) {
    if (a.length === 2) {
      return {
        ...setCross(a[0], a[1]),
        ...setEach(a[0], a[1], a[0], a[1]),
      }
    }
    if (a.length === 4) {
      return {
        ...setEach(a[0], a[1], a[2], a[3]),
      }
    }
  }
  return null
}

// FONT SIZE
// ----------------------------------------------------------------------------
// export const setFontSize

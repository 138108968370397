import { css as eCss } from '@emotion/core'

export const composeCss = whatCss => {
  const tc = Array.isArray(whatCss) ? 'array' : typeof whatCss
  switch (tc) {
    case 'string':
      return eCss`${whatCss}`
    case 'array':
      return whatCss.map(cssComposant => composeCss(cssComposant))
    case 'undefined':
    case 'null':
    case 'object':
    default:
      return whatCss
  }
}
export const spreadCss = css => (css ? { css: composeCss(css) } : {})

// import { useContext, ThemeContext, RenderSchemaContext } from '../contexts'

// TODO: Find a better way to use this

const Component = ({ theming, renderSchema, schema }) => {
  return renderSchema
    ? renderSchema({
        schema: schema({
          theming,
        }),
      })
    : null
}

export default Component

import { renderSchema } from '../functions'
import { object as o, protectAndReplaceEmails } from '../../utils'

const CoreShort = ({ children, text, tag: Tag, ...props }) => {
  if (!children && !text) return children

  const hasProps = !o.isEmpty(props)
  const s = protectAndReplaceEmails(text || children)
  // const s = text || children

  if (Tag || hasProps) {
    return renderSchema({
      schema: {
        component: Tag || 'span',
        children: s,
        ...props,
      },
    })
  }

  return text || children
}

export default CoreShort

import React, { useState, useEffect } from 'react'
import { renderSchema } from '../functions'
import protectAndReplaceEmails from '../../utils/protectAndReplaceEmails'

const CoreLong = ({ children, html, tag: Tag = 'div', ...props }) => {
  // const typeWin = typeof window
  // const [s, setS] = useState(protectAndReplaceEmails(html || children || ''))
  //
  // useEffect(() => {
  //   setS(protectAndReplaceEmails(html || children || ''))
  // }, [typeWin])
  // if (typeof children !== 'string') {
  //   throw new Error('Long should only receive string children')
  // }
  if (!children && !html) return children
  const s = protectAndReplaceEmails(html || children || '')

  const htmlIs = html && html !== '<p><br></p>'
  const childrenIs = children && children !== '<p><br></p>'

  return childrenIs || htmlIs
    ? renderSchema({
        schema: {
          component: Tag,
          ...props,
          className: `Long ${props.className}`,
          css: {
            'ul, ol': {
              paddingLeft: '1em',
              marginBottom: `.2em`,
            },
            ul: {
              listStyle: `disc outside none`,
            },
            p: {
              marginBottom: `.2em`,
            },
            // ' .cryptedmail:after': {
            //   content: `attr(data-name) "@" attr(data-domain) "." attr(data-tld)`,
            // },
            // lineHeight: 1.4,
          },
          dangerouslySetInnerHTML: {
            __html: s,
          },
        },
      })
    : null

  // const { ssr, browser } = protectAndReplaceEmails(html || children || '')
  // console.log({ ssr, browser })

  // return (
  //   <Tag
  //     {...{
  //       css: {
  //         'ul, ol': {
  //           paddingLeft: '1em',
  //           marginBottom: `.2em`,
  //         },
  //         ul: {
  //           listStyle: `disc outside none`,
  //         },
  //         p: {
  //           marginBottom: `.2em`,
  //         },
  //         // ' .cryptedmail:after': {
  //         //   content: `attr(data-name) "@" attr(data-domain) "." attr(data-tld)`,
  //         // },
  //         // lineHeight: 1.4,
  //       },
  //       // dangerouslySetInnerHTML: {
  //       //   __html: typeof window === 'undefined' ? ssr : browser,
  //       // },
  //       ...props,
  //     }}
  //   >
  //     {typeof window === 'undefined' ? ssr : browser}
  //   </Tag>
  // )

  // return typeof window === 'undefined'
  //   ? renderSchema({
  //       schema: {
  //         component: Tag,
  //         className: 'Long',
  //         css: {
  //           'ul, ol': {
  //             paddingLeft: '1em',
  //             marginBottom: `.2em`,
  //           },
  //           ul: {
  //             listStyle: `disc outside none`,
  //           },
  //           p: {
  //             marginBottom: `.2em`,
  //           },
  //           // ' .cryptedmail:after': {
  //           //   content: `attr(data-name) "@" attr(data-domain) "." attr(data-tld)`,
  //           // },
  //           // lineHeight: 1.4,
  //         },
  //         dangerouslySetInnerHTML: {
  //           __html: ssr,
  //         },
  //         ...props,
  //       },
  //     })
  //   : renderSchema({
  //       schema: {
  //         component: Tag,
  //         className: 'Long',
  //         css: {
  //           'ul, ol': {
  //             paddingLeft: '1em',
  //             marginBottom: `.2em`,
  //           },
  //           ul: {
  //             listStyle: `disc outside none`,
  //           },
  //           p: {
  //             marginBottom: `.2em`,
  //           },
  //           // ' .cryptedmail:after': {
  //           //   content: `attr(data-name) "@" attr(data-domain) "." attr(data-tld)`,
  //           // },
  //           // lineHeight: 1.4,
  //         },
  //         dangerouslySetInnerHTML: {
  //           __html: browser,
  //         },
  //         ...props,
  //       },
  //     })
}

export default CoreLong

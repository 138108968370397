// TODO: Create default colors based on named colors provided by the user
// EX: dark by taking the darkest from all defined colors
// export const complementColors = userColors => {
//   const {
//     dark,
//     light,
//     primary,
//     secondary,
//     success,
//     danger,
//     warning,
//     info,
//     link,
//     disabled,
//   } = userColors
//
//   return null
// }

export const createCss = ({
  palette: {
    // name,
    neutral,
    primary,
    // secondary,
    heading,
    background,
    body,
    border,
    linkHover,
  },
}) => {
  const cssBorder = {
    borderColor: border,
  }
  const cssHover = {
    ':hover': {
      color: background,
      backgroundColor: body,
      ...cssBorder,
    },
  }
  const cssBase = {
    color: body,
    ...cssBorder,
    ' h1, h2, h3, h4, h5, h6': {
      color: heading,
    },
    ' a:hover': {
      color: linkHover,
    },
    ' *::selection': {
      background: linkHover,
      color: background,
    },
    button: {
      color: body,
      backgroundColor: background,
      ...cssBorder,
      ...cssHover,
    },
  }
  const cssBackground = {
    backgroundColor: background,
  }
  const cssForm = {
    ' input, textarea': {
      // TODO: better choice than primary and neutral here or make sure that we define them in the theme
      backgroundColor: neutral,
      color: primary,
      ...cssBorder,
    },
    " input[type='submit']": {
      color: body,
      backgroundColor: background,
      ...cssBorder,
      ...cssHover,
    },
  }
  const cssGlobal = {
    backgroundColor: background,
    color: body,
    ...cssBorder,
    ' h1, h2, h3, h4, h5, h6': {
      color: heading,
    },
    ' a:hover': {
      color: linkHover,
    },
    ' *::selection': {
      background: linkHover,
      color: background,
    },
    ' a.button, button': {
      color: body,
      backgroundColor: background,
      ...cssBorder,
      ':hover': {
        color: background,
        backgroundColor: body,
        ...cssBorder,
      },
    },
  }
  const css = {
    border: cssBorder,
    hover: cssHover,
    base: cssBase,
    background: cssBackground,
    form: cssForm,
    global: cssGlobal,
    block: {
      ...cssBase,
      ...cssBackground,
    },
    button: {
      ...cssBase,
      ...cssBackground,
      ...cssHover,
    },
    all: {
      ...cssBase,
      ...cssBackground,
      ...cssForm,
    },
  }

  return css
}

export const chooseColor = (colors, precise, fallbacks) => {
  const cs = colors || {}
  const isPrecise = cs[precise] || precise
  if (isPrecise) {
    return isPrecise
  }
  if (Array.isArray(fallbacks)) {
    const isFallback = fallbacks.reduce(
      (chosenFb, currentFb) => chosenFb || cs[currentFb] || currentFb,
    )
    return isFallback
  }
  throw new Error('no color found')
}

const makeContrast = c => ({
  neutral: c.primary,
  primary: c.neutral,
  secondary: c.secondary,
  background: c.body,
  body: c.background,
  heading: c.background,
  border: c.background,
  linkHover: c.linkHover,
})
const makeFunky = c => ({
  neutral: c.neutral,
  primary: c.secondary,
  secondary: c.primary,
  background: c.background,
  body: c.linkHover,
  heading: c.linkHover,
  border: c.linkHover,
  linkHover: c.body,
})
const classic = {
  neutral: ['neutral', 'background'],
  primary: ['primary', 'body', 'heading', 'border'],
  secondary: ['secondary', 'linkHover'],

  background: ['background', 'neutral'],
  body: ['body', 'primary'],
  heading: ['heading', 'primary'],
  border: ['border', 'primary'],
  linkHover: ['linkHover', 'secondary'],
}
const contrast = makeContrast(classic)
const funky = makeFunky(classic)
const funkyContrast = makeContrast(funky)
const disco = makeFunky(funkyContrast)
const deep = makeFunky(contrast)

const variationsMapping = {
  classic,
  contrast,
  funky,
  funkyContrast,
  disco,
  deep,
  // To make more combos we should probably change the css implementations of these combos
}

export const createPalette = ({
  colors,
  defaultPalette = {},
  palette: {
    name,
    ...p
    // neutral, primary, secondary, background, body, heading, border, linkHover,
  },
  variation = 'classic',
}) => {
  if (!variationsMapping[variation]) {
    throw new Error(`The palette variation "${variation}" does not exist`)
  }
  // Simplify the use of the chooseColor function by
  // 1. creating fallback options from mapping and
  // 2. providing all the colors
  const chooseCol = (preciseStr, [fbStr0, ...fallbacksStr]) => {
    const precise = p[preciseStr]
    const fallbacks = [
      // if there is a defaultPalette, use it as the first fallback option
      // if a variation was set, it is important to pick the variant in the defaultPalette
      defaultPalette[fbStr0],
      // ...fallbacksStr.map(str => defaultPalette[str]),
      // then use normal fallbacks from the variationsMapping
      ...fallbacksStr.map(str => p[str]),
    ]

    return chooseColor(colors, precise, fallbacks)
  }

  const populatePalette = Object.entries(variationsMapping[variation]).reduce(
    (accu, [newKey, fallbacks]) => {
      return {
        ...accu,
        [newKey]: chooseCol(newKey, fallbacks),
      }
    },
    {},
  )

  const palette = {
    name,
    ...populatePalette,
    // This function creates properties like this one
    // neutral: chooseCol('neutral'),
  }

  return palette
}

export const createPalettes = ({ colors, palettes: userPalettes }) => {
  const palettes = userPalettes.map(palette => {
    const p = createPalette({ colors, palette })
    const css = createCss({ palette: p })
    return {
      ...p,
      css,
    }
  })

  return palettes
}

// export const pickAndCustomizePalette = ({
//   colors,
//   palettes = [],
//   palette: namedOrCustomPalette = {},
// }) => {
//   const { name } = namedOrCustomPalette
//   const defaultPalette =
//     name && palettes.filter(({ name: currentName }) => name === currentName)[0]
//   const palette = createPalette({
//     colors,
//     defaultPalette,
//     palette: namedOrCustomPalette,
//   })
//   const css = createCss({ palette })
//
//   return {
//     ...palette,
//     css,
//   }
// }

export const pickAndCustomizePalette = ({
  colors,
  palettes = [],
  palette: nameOrPosOrCustomPalette,
  variation,
  cb,
} = {}) => {
  let name = ''
  let pos = null
  let defaultPalette = {}
  if (typeof nameOrPosOrCustomPalette === 'string') {
    name = nameOrPosOrCustomPalette
    defaultPalette =
      name &&
      palettes.filter(({ name: currentName }) => name === currentName)[0]
  }
  if (/number|undefined/.test(typeof nameOrPosOrCustomPalette)) {
    pos = nameOrPosOrCustomPalette || 0
    defaultPalette = palettes[pos]
  }

  const palette = createPalette({
    colors,
    defaultPalette,
    palette:
      typeof nameOrPosOrCustomPalette === 'object'
        ? nameOrPosOrCustomPalette
        : {},
    variation,
  })
  const modPalette = cb && typeof cb === 'function' ? cb(palette) : null
  const definitivePalette = { ...palette, ...modPalette }

  const css = createCss({ palette: definitivePalette })

  return {
    ...definitivePalette,
    css,
  }
}

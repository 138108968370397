import { renderSchema } from '../functions'

const List = ({ tag: Tag, list, components: comps, ...props }) => {
  const components = Array.isArray(comps) ? [...comps] : [comps]
  const componentsLength = components.length

  return list
    ? renderSchema({
        schema: [
          {
            component: Tag || 'div',
            ...props,
            children: list
              .filter(child =>
                // returns false unless at least one value is defined in the child object
                Object.values(child).reduce(
                  (curr, cPropVal) => curr || cPropVal,
                  false,
                ),
              )
              .map((child, i, array) => {
                const length = (array && array.length) || 0
                const component = components[i % componentsLength]

                return {
                  component: component || 'Section',
                  index: i,
                  length,
                  ...child,
                }
              }),
          },
        ],
      })
    : null

  // return (
  //   <BgImage
  //     {...{
  //       src: bgImage,
  //       css: composeCss(`
  //           color: purple;
  //           `),
  //     }}
  //   >
  //     <img
  //       {...{
  //         src: image,
  //         alt: 'Intro',
  //         css: {
  //           width: 250,
  //         },
  //       }}
  //     />
  //     <h1>{title}</h1>
  //     <div {...{ dangerouslySetInnerHTML: { __html: text } }} />
  //   </BgImage>
  // )
}

export default List

/* eslint-disable no-undef, react/prop-types */

import React from 'react'
// import path from 'path';
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
// import config from '../config';

// const BRANCH = process.env.BRANCH
// const NAME = /^site/.test(BRANCH) ? BRANCH.split('/')[1] : 'feed-by-design'
//
// const { default: data } = require(`../../data/${NAME}/index.js`)
// const { data } = require('../../data')

// const siteConfig = data.website.metadata
// has become "defaults" here

// import { Consumer as TranslationsConsumer } from '../containers/Translations'

const defaults = {
  defaultLocale: '',
  locales: [''],
  name: '',
  titleTemplate: '',
  baseUrl: '',
  favicon: '',
  socialImage: '',
  title: {
    fr: '',
  },
  description: {
    fr: '',
  },
  defaultTitle: '',
}

const SEO = ({
  currentLocale,
  baseUrl: urlProp,
  icon: iconProp,
  lang: langPropDefined,
  socialImage: { url: imageProp } = { url: '' },
  name: nameProp,
  title: titleProp,
  description: descriptionProp,
  children,
  ...rest
}) => {
  const defaultLocale = defaults.defaultLocale || defaults.locales[0] || 'fr'
  const langProp = langPropDefined || currentLocale || 'fr'
  const name = nameProp || defaults.name
  const complementTitle =
    name && name[langProp]
      ? ` | ${name[langProp]}`
      : defaults.titleTemplate && defaults.titleTemplate.substring(2)

  // Either the default url or just the extension
  const url =
    (urlProp &&
      urlProp === true && [
        <link
          {...{ key: 'default', rel: 'canonical', href: defaults.baseUrl }}
        />,
        <meta
          {...{ key: 'fb', property: 'og:url', content: defaults.baseUrl }}
        />,
      ]) ||
    (urlProp &&
      typeof urlProp === 'string' && [
        <link
          {...{
            key: 'default',
            rel: 'canonical',
            href: `${defaults.baseUrl}${
              langProp !== defaultLocale ? `/${langProp}` : ''
            }${urlProp}`,
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:url',
            content: `${defaults.baseUrl}${
              langProp !== defaultLocale ? `/${langProp}` : ''
            }${urlProp}`,
          }}
        />,
      ]) ||
    null

  // Either the default url or just the extension
  const icon =
    (iconProp && iconProp === true && (
      <link
        {...{
          rel: 'icon',
          type: 'image/png',
          href: defaults.favicon,
          sizes: '75x75',
        }}
      />
    )) ||
    (iconProp && typeof iconProp === 'object' && (
      <link
        {...{
          rel: 'icon',
          type: iconProp.type || 'image/png',
          href: iconProp.href,
          sizes: iconProp.sizes || '32x32',
        }}
      />
    )) ||
    null

  const lang =
    (langProp && typeof langProp === 'string' && (
      <html lang={langProp} {...{ amp: false }} />
    )) ||
    null

  const image =
    (imageProp &&
      typeof imageProp === 'boolean' && [
        <meta
          {...{ key: 'default', name: 'image', content: defaults.socialImage }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:image',
            content: `${defaults.baseUrl}${defaults.socialImage}`,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:image',
            content: `${defaults.baseUrl}${defaults.socialImage}`,
          }}
        />,
      ]) ||
    (imageProp &&
      typeof imageProp === 'string' &&
      imageProp.length && [
        <meta {...{ key: 'default', name: 'image', content: imageProp }} />,
        <meta
          {...{
            key: 'fb',
            property: 'og:image',
            content: `${imageProp}`,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:image',
            content: `${imageProp}`,
          }}
        />,
      ]) ||
    null

  const title =
    (titleProp &&
      titleProp === true && [
        <title {...{ key: 'default' }}>{defaults.title[langProp]}</title>,
        <meta
          {...{
            key: 'fb',
            property: 'og:title',
            content: `${defaults.title[langProp]}${complementTitle}`,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:title',
            content: `${defaults.title[langProp]}${complementTitle}`,
          }}
        />,
      ]) ||
    (titleProp &&
      typeof titleProp === 'string' && [
        <title {...{ key: 'default' }}>{titleProp}</title>,
        <meta
          {...{
            key: 'fb',
            property: 'og:title',
            content: `${titleProp}${complementTitle}`,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:title',
            content: `${titleProp}${complementTitle}`,
          }}
        />,
      ]) ||
    null

  const description =
    (descriptionProp &&
      descriptionProp === true && [
        <meta
          {...{
            key: 'default',
            name: 'description',
            content: defaults.description[langProp],
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:description',
            content: defaults.description[langProp],
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:description',
            content: defaults.description[langProp],
          }}
        />,
      ]) ||
    (descriptionProp &&
      typeof descriptionProp === 'string' && [
        <meta
          {...{
            key: 'default',
            name: 'description',
            content: descriptionProp,
          }}
        />,
        <meta
          {...{
            key: 'fb',
            property: 'og:description',
            content: descriptionProp,
          }}
        />,
        <meta
          {...{
            key: 'twt',
            name: 'twitter:description',
            content: descriptionProp,
          }}
        />,
      ]) ||
    null

  // const postMeta = postData.frontmatter || {}
  //
  // const title = postMeta.title || config.title
  // const description =
  //   postMeta.description || postData.excerpt || config.description
  // const image = `${config.url}${postImage}` || config.image
  // const url = postMeta.slug
  //   ? `${config.url}${path.sep}${postMeta.slug}`
  //   : config.url
  // const datePublished = isBlogPost ? postMeta.datePublished : false
  //
  // const schemaOrgJSONLD = getSchemaOrgJSONLD({
  //   isBlogPost,
  //   url,
  //   title,
  //   image,
  //   description,
  //   datePublished,
  // })

  return (
    <Helmet
      {...{
        titleTemplate: defaults.titleTemplate,
        defaultTitle: defaults.defaultTitle,
      }}
    >
      {/* General tags */}
      {url}
      {icon}
      {lang}
      {image}
      {title}
      {description}

      <meta property="og:type" content="website" />

      {/* Schema.org tags */}
      {/* <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script> */}

      {/* OpenGraph tags */}
      {/*
      {isBlogPost ? <meta property="og:type" content="article" /> : null}
      <meta property="fb:app_id" content={config.fbAppID} /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {/* <meta name="twitter:creator" content={config.twitter} /> */}

      {children}
    </Helmet>
  )
}

SEO.propTypes = {
  baseUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  lang: PropTypes.string.isRequired,
  socialImage: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

SEO.defaultProps = {
  baseUrl: false,
  icon: false,
  // lang: false,
  socialImage: false,
  title: false,
  description: false,
}
export default SEO
// export default props => (
//   <TranslationsConsumer>
//     {({ currentLocale }) => (
//       <SEO
//         {...{
//           currentLocale,
//           ...props,
//         }}
//       />
//     )}
//   </TranslationsConsumer>
// )

import { map, reduce, mergeDeepRight } from 'ramda'
import modularScale from 'modularscale-js'

const mergeDeepAll = reduce(mergeDeepRight, {})

const cssLockCalc = ({
  min,
  max,
  minW,
  maxW,
  unit,
  // widthUnit,
  fullScaleWidth,
  i,
  // noVWScale,
  cols,
}) => {
  const cMin = min[i] || min
  const cMax = max[i] || max
  const cMinW = minW[i] || minW
  const cMaxW = maxW[i] || maxW
  const cCols = (cols && cols[i]) || cols || 1
  // initial expression is: min{unit} + (max - min) * ( (100vw - minW{unit}) / (maxW - minW) )
  // <=> min{unit} + ((max - min) / (maxW - minW)) * (100vw - minW{unit})
  const difValues = cMax - cMin
  const difWidths = (cMaxW - cMinW) * cCols
  const divDifs = difValues / difWidths
  // <=> min{unit} + divDifs * (100vw - minW{unit})
  // <=> divDifs * 100vw - divDifs * minW{unit} + min{unit}
  const addRest = cMin - divDifs * cMinW
  const addRestSigned = addRest >= 0 ? `+ ${addRest}` : `- ${-1 * addRest}`
  // NOTE: need to test for more negative values ???
  // const calc = noVWScale ?
  return {
    cMinW,
    cMaxW,
    cMin,
    cMax,
    difValues,
    calc: `${divDifs} * ${fullScaleWidth} ${addRestSigned}${unit}`,
  }
}

// const cssFluid = ({
//   property = 'fontSize',
//   properties: propties,
//   min = 1,
//   max = 1,
//   minW: minWidth = [30],
//   maxW: maxWidth = [60],
//   unit = 'em',
//   widthUnit = 'vw', // use vmin or vw. % does not seem to work
//   noMin,
//   noMax,
// }) = {
//   return null
// }

export const fluidLocks = locksProps => {
  if (Array.isArray(locksProps)) {
    return mergeDeepAll(map(fluidLocks, locksProps))
  }

  const {
    // property = 'fontSize',
    properties: propties,
    min = 1,
    max = 1,
    minW: minWidth = [30],
    maxW: maxWidth = [60],
    unit = 'em',
    // widthUnit = 'vw', // use vmin or vw. % does not seem to work
    fullScaleWidth = '100vw',
    noMin,
    noMax,
    // noVWScale,
    cols,
    takesCols = 1,
  } = locksProps

  // TODO: better test entering values
  const properties = Array.isArray(propties) ? propties : [propties]
  const minW = Array.isArray(minWidth) ? minWidth : [minWidth]
  const maxW = Array.isArray(maxWidth) ? maxWidth : [maxWidth]
  // NOTE: Apparently em is safer for media queries and works the same as rem. Both use the browser settings font size
  const mqUnit = /em/.test(unit) ? 'em' : unit

  let returnVals = []

  // For each breakpoint we will construct the queries for each prop
  const minWLength = minW.length
  // We take the inverse order for the array because we use minWidth for media queries and bigger values should be applied on top of the small ones
  // for (let i = minWLength - 1; i > -1; i -= 1) {
  for (let i = 0; i < minWLength; i += 1) {
    const { calc, difValues, cMinW, cMaxW, cMin, cMax } = cssLockCalc({
      min: min * takesCols,
      max: max * takesCols,
      minW,
      maxW,
      unit,
      // widthUnit,
      fullScaleWidth,
      i,
      // noVWScale,
      cols,
    })
    const multipleCols =
      cols && Array.isArray(cols) ? cols[cols.length - 1] > 1 : cols > 1
    const valForThatBreakPointRange = {}
    properties.forEach(p => {
      if (i === 0 && !noMin) {
        // only set the default for the real min
        valForThatBreakPointRange[p] = `${cMin}${unit}`
      }

      valForThatBreakPointRange[
        `@media screen and (min-width: ${cMinW}${mqUnit})`
      ] = {
        ...valForThatBreakPointRange[
          `@media screen and (min-width: ${cMinW}${mqUnit})`
        ],
        [p]: [`${cMax - difValues / 2}${unit}`, `calc(${calc})`],
        // [property]: `calc(${min}${unit} + ${difValues} * ( (100${widthUnit} - ${minW}${unit}) / ${difWidths} ))`,
      }

      if (i === minWLength - 1 && !noMax) {
        // only set the max when this is the last array entry
        valForThatBreakPointRange[
          `@media screen and (min-width: ${cMaxW}${mqUnit})`
        ] = {
          ...valForThatBreakPointRange[
            `@media screen and (min-width: ${cMaxW}${mqUnit})`
          ],
          [p]: `${multipleCols ? cMin : cMax}${unit}`,
        }
      }
    })

    returnVals = [...returnVals, valForThatBreakPointRange]
  }

  return mergeDeepAll(returnVals)

  // return as a string
  // return `
  // html { font-size: ${min}${unit}; }
  // @media screen and (min-width: ${minW}${unit}) { html { font-size: calc(${calc}); } }
  // @media screen and (min-width: ${maxW}${unit}) { html { font-size: ${max}${unit}; } }
  // `

  // return {
  //   fontSize: `calc( ${minFontSize}${unit} + (${maxFontSize} - ${minFontSize}) * ( (100vw - ${minVW}${unit}) / ( ${maxVW} - ${minVW}) ))`,
  // } // -> DOS NOT WORK AS EXPECTED - need media queries anyway
  // REF: https://www.madebymike.com.au/writing/precise-control-responsive-typography/
}

export const fluidColumn = ({ properties, maxCols, fullWidth, takesCols }) => {
  const min = fullWidth / maxCols
  const max = min * 2
  const cols = []
  const minW = []
  const maxW = []
  for (let i = 0; i < maxCols; i += 1) {
    const step = i + 1
    const cMin = step * min
    cols.push(step)
    minW.push(cMin)
    maxW.push(cMin + min)
  }

  return fluidLocks({
    properties,
    min,
    max,
    cols,
    minW,
    maxW,
    noMin: true,
    noMax: true,
    fullScaleWidth: `100%`,
    takesCols,
  })
}

// Think of the best way to implement units
// currently it is 60em / 120. 0.5 should be computed based on theme values
// export const u = numUnits => `${numUnits * 0.5}em`
// export const cell = numUnits => `${numUnits * 5}em`
// export const col = numCols => `${numCols * 15}em`

// excessive vertical spacing because line height is applied above AND below the font
// this computes the excessive space on one side
export const excessVHeight = ({ fontSize, lineHeight }) =>
  (fontSize * lineHeight - fontSize) / 2

const scales = {
  majorSecond: [1.802, 1.602, 1.424, 1.266, 1.125, 1],
  minorThird: [2.488, 2.074, 1.728, 1.44, 1.2, 1],
  majorThird: [3.052, 2.441, 1.953, 1.563, 1.25, 1],
  perfectFourth: [4.209, 3.157, 2.369, 1.777, 1.333, 1],
  augmentedFourth: [5.653, 3.998, 2.827, 1.999, 1.414, 1],
  ratios: {
    minorSecond: 1.067,
    majorSecond: 1.125,
    minorThird: 1.2,
    majorThird: 1.25,
    perfectFourth: 1.333,
    augFourth: 1.414,
    perfectFifth: 1.5,
    minorSixth: 1.6,
    goldenSection: 1.618,
    majorSixth: 1.667,
    minorSeventh: 1.778,
    majorSeventh: 1.875,
    octave: 2,
    majorTenth: 2.5,
    majorEleventh: 2.667,
    majorTwelfth: 3,
    doubleOctave: 4,
  },
}

export { modularScale }

export const ratioFromDif = ({ min, max, steps }) => (max / min) ** (1 / steps)

export const headings = ({ base = 1, ratio }) => {
  return [6, 5, 4, 3, 2, 1, 0, -1, -2].map(pos =>
    modularScale(pos, { base, ratio }),
  )
}

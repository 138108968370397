import React from 'react'
import { Link as GatsbyLink, navigate } from 'gatsby'

// TODO: should create own function that uses gatsby's navigate or window.open(`https://url`, '_blank') if external link
export { navigate }

// import { renderSchema } from '../core/functions'

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const LinkOnly = ({ children, text, to, activeClassName, ...props }) => {
  if (!to) {
    return text || children || null
  }
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const isInternal = /^\/(?!\/)/.test(to)
  const isExternal = /^(http|www)/.test(to)
  // const isScroll = /^#/.test(to) // Does not work. Leads to infinite loop or memory leak on dev server
  const isMailto = /^mailto/.test(to)
  const isFile = /\.[0-9a-z]+$/i.test(to) && !isMailto
  const isSSR = typeof window === 'undefined'

  // if (isScroll) {
  //   return <Link {...{ to, ...props }}>{text || children}</Link>
  // }

  if (isFile) {
    return (
      <a {...{ href: to, target: '_blank', ...props }}>{text || children}</a>
    )
  }
  if (isExternal) {
    return (
      <a
        {...{
          href: to,
          target: '_blank',
          rel: 'noopener noreferrer',
          ...props,
        }}
      >
        {text || children}
      </a>
    )
  }
  // Use Gatsby Link for internal links, and <a> for others
  if (isInternal) {
    return (
      <GatsbyLink {...{ to, activeClassName, ...props }}>
        {text || children}
      </GatsbyLink>
    )
  }

  return isMailto && isSSR ? null : (
    <a {...{ href: to, ...props }}>{text || children}</a>
  )
}

const Link = ({
  tag: Tag,
  to,
  text,
  children,
  activeClassName,
  download,
  href,
  hrefLang,
  media,
  ping,
  rel,
  target,
  type,
  ...props
}) => {
  return Tag ? (
    <LinkOnly
      {...{
        ...(to && { to }),
        // ...(text && { text }),
        ...(activeClassName && { activeClassName }),
        ...(download && { download }),
        ...(href && { href }),
        ...(hrefLang && { hrefLang }),
        ...(media && { media }),
        ...(ping && { ping }),
        ...(rel && { rel }),
        ...(target && { target }),
        ...(type && { type }),
      }}
    >
      <Tag
        {...{
          ...((text || children) && { children: text || children }),
          ...props,
        }}
      />
    </LinkOnly>
  ) : (
    <LinkOnly
      {...{
        ...(to && { to }),
        ...(text && { text }),
        ...(children && { children }),
        ...(activeClassName && { activeClassName }),
        ...(download && { download }),
        ...(href && { href }),
        ...(hrefLang && { hrefLang }),
        ...(media && { media }),
        ...(ping && { ping }),
        ...(rel && { rel }),
        ...(target && { target }),
        ...(type && { type }),
        ...props,
      }}
    />
  )
}

export default Link

// import { reduce, values } from 'rambda'
// import GImg from 'gatsby-image'
// import RIImg from 'react-ideal-image'
import { renderSchema } from '../functions'

// const hasValues = o =>
//   reduce((accu, val) => Boolean(accu || val), false, values(o))

const BgImage = ({
  children,
  tag: Tag,
  data,
  src: specificSrc,
  fit,
  overlay,
  transparentizeOverlay = false,
  transparentizeImage = false,
  parallax,
  transition = '0.5s',
  // shape,
  // NOTE: things like following to shape section ??
  // paddingBottom: `${length > 3 ? 100 / 3 : 100 / length}%`
  css,
  ...props
}) => {
  // const { props: dataProps } = data
  // const imgProps = {
  //   ...dataProps,
  //   ...(specificSrc && { src: specificSrc }),
  // }
  const src = specificSrc || data?.props?.src

  return renderSchema({
    schema: {
      component: Tag || 'div',
      css: [
        // Best solution but does not work if we want to hide the overlay
        !transparentizeOverlay && {
          background: `
            ${overlay ? `linear-gradient(${overlay},${overlay}),` : ''}
            ${src ? `url("${src}")` : 'none'}`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: fit || 'cover',
        },
        parallax && {
          backgroundAttachment: 'fixed',
        },
        overlay && {
          background: 'none',
          position: `relative`,

          '&:before, &:after': {
            content: `''`,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: fit || 'cover',
            transition: `opacity ${transition}`,
            pointerEvents: `none`,
          },
          // before goes behind and gets the image.
          ':before': {
            backgroundImage: src ? `url("${src}")` : 'none',
            zIndex: -1,
            opacity: 1,
          },
          // after goes above and gets the overlay.
          ':after': {
            zIndex: -1,
            opacity: 1,
            background: overlay,
          },
        },
        transparentizeOverlay && {
          ':hover:after': {
            opacity: 0,
            transition: `opacity ${transition}`,
          },
        },
        transparentizeImage && {
          ':hover:before': {
            opacity: 0,
            transition: `opacity ${transition}`,
          },
        },
        // overlay && {
        //   '&:before': {
        //     position: `absolute`,
        //     top: 0,
        //     right: 0,
        //     bottom: 0,
        //     left: 0,
        //     backgroundColor: `${overlay}`,
        //     content: `''`,
        //     display: `block`,
        //     // TODO:
        //     // zIndex: -100,
        //     // zIndex: 1,
        //
        //     // pointerEvents: `none`,
        //     // opacity: `0.85`,
        //     // transition: `opacity ${duration.banner} ease`,
        //     // transitionDelay: `0.75s`,
        //   },
        // },
        css,
      ],
      children,
      ...props,
    },
  })
}

export default BgImage

import React from 'react'
// import emotionReset from 'emotion-reset'
import { Global, css } from '@emotion/core'
// import { normalize } from 'polished'
// import { ThemeProvider } from 'emotion-theming'

// import cssRemedy, { quotes } from '../utils/cssRemedy'

const CssReset = ({ children }) => {
  return (
    <>
      <Global
        styles={[
          // css`${emotionReset.styles}`,
          // ------------------------------------------------------------------
          css`
            ${'' /* this is normalize I think */}
            html {
              font-size: 100%;
              box-sizing: border-box;
            }
            ${'' /* pretier-ignore-next-line */}
            body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,
            pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,
            s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,
            ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,
            th,td,article,aside,canvas,details,embed,figure,figcaption,footer,
            header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video {
              margin: 0;
              padding: 0;
              border: 0;
              ${'' /* font-size: 100%; */}
              font-size: inherit;
              font: inherit;
              vertical-align: baseline;
              box-sizing: border-box;
            }
            ${'' /* pretier-ignore-next-line */}
            article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section {
              display: block;
              box-sizing: border-box;
            }
            body {
              ${'' /* line-height: 1; */}
            }
            ${'' /* pretier-ignore-next-line */}
            ol,ul {
              list-style: none;
            }
            ${'' /* pretier-ignore-next-line */}
            blockquote,q {
              quotes: none;
            }
            ${'' /* pretier-ignore-next-line */}
            blockquote:before, blockquote:after, q:before, q:after {
              content: '';
              content: none;
            }
            table {
              border-collapse: collapse;
              border-spacing: 0;
            }
          `,
          // --------------------------------------------------------------------
          // normalize(),
          // ------------------------------------------------------------------
          // css`
          //   ${cssRemedy} ${quotes}
          // `,
          {
            a: {
              color: 'inherit',
              cursor: 'pointer',
              textDecoration: 'none', // TODO: decide if this is what we want
            },
            button: {
              boxSizing: 'border-box',
              cursor: 'pointer',
              fontSize: 'inherit',
              backgroundColor: 'inherit',
              color: 'inherit',
              alignItems: 'flex-start',
              textAlign: 'center',
              padding: 0,
              border: 'solid 1px',
              borderColor: 'inherit',
              borderImage: 'initial',
              borderRadius: 0,
            },
            'strong, b': {
              fontWeight: 'bold',
            },
            em: {
              fontStyle: 'italic',
            },
            'strike, s, del, ins': {
              fontWeight: 'initial',
              fontStyle: 'initial',
            },
          },
          // ------------------------------------------------------------------
          //
          // NOTE: dynamic import does not work
          // import('polished').then(({ normalize }) => {
          //   // eslint-disable-next-line no-console
          //   console.log(normalize())
          //   return { ...normalize() }
          // }),
        ]}
      />
      {children}
    </>
  )
}

export default CssReset
